import { get } from 'lodash';
import { createPerformanceTracker } from '@wix/communities-blog-client-common';
import {
  createLogger,
  doRouting,
  createControllerId,
  getSectionPathname,
  handleLocationChange,
  isFrameless,
} from '../../common/controller/helpers';
import getEnvironment from '../../common/services/get-environment';
import { getSectionUrl } from '../services/get-section-url';
import { BLOG_SECTION_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { createReduxStore } from './create-redux-store';
import reducers from '../reducers';
import { createRouter } from './create-router';
import { handleProvisioning } from '../../common/services/handle-provisioning';
import {
  initializeActions,
  initializePromisifiedActions,
  initializeStoreBaseData,
  refreshDataOnLogin,
} from './init-actions';
import { setRouterMatch } from '../../common/router';
import { createMonitoring } from '../../common/services/create-monitoring';
import { subscribeToChange } from '../../common/services/state-optimizer';
import { simulateControllerError } from '../../common/services/simulate-error';
import { isRtlLanguage } from '../../common/services/is-rtl-language';
import listenToEditModeChange from '../../common/services/listen-to-edit-mode-change';
import listenToSettingsChange, {
  handleSettingsEvent,
} from '../../common/services/listen-to-settings-change';
import { getInitialStateVersions } from '../../common/services/state-optimizer/change-detector';
import { initLazyActions } from '../../common/controller/lazy-actions';
import { isInCategory } from '../../common/services/detect-route';
import { fetchSiteProperties } from '../../common/store/site-properties/site-properties-actions';
import { getCurrentSiteLanguage } from '../../common/services/get-current-site-language';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import { initI18N } from '../../common/services/controller-translate';
import { getTranslations } from '../../common/store/translations/translations-selectors';
import listenToInstanceChange from '../../common/services/listen-to-instance-change';

const isProduction = process.env.NODE_ENV === 'production';

export const createFeedPageController = (
  { appParams, compId, config, setProps, wixCodeApi, platformAPIs },
  allCtrls,
  context,
  flowAPI,
) => {
  const { isEditor, isPreview, isSSR, isDebug } = getEnvironment(wixCodeApi);

  const { sentry, fedopsLogger } = createMonitoring({ flowAPI });

  const language = getCurrentSiteLanguage(wixCodeApi, flowAPI);

  const bundleName = 'blog';

  const log = createLogger(isDebug, isProduction);
  const perf = createPerformanceTracker(bundleName, { isDebug, isSSR });

  log('createFeedPageController', {
    appParams,
    allCtrls,
    wixCodeApi,
    isSSR,
    language,
  });

  let store;

  const pageReady = async () => {
    const pageReadyMarker = perf.trackStart(
      `${new Date().toISOString().slice(11)} pageReady`,
    );
    log('createFeedPageController.pageReady -> start');

    const sectionUrl = await getSectionUrl({
      wixCodeApi,
      instanceId: appParams.instanceId,
      sectionId: BLOG_SECTION_ID,
    });

    simulateControllerError(wixCodeApi, 'feed-page.pageReady');

    // eslint-disable-next-line
      let router;
    const getRouter = () => router;

    initLazyActions({ isSSR });

    let marker = perf.trackStart('createReduxStore', pageReadyMarker);
    store = createReduxStore({
      appParams,
      wixCodeApi,
      compId,
      reducers,
      fedopsLogger,
      getRouter,
      platformAPIs,
      isSSR,
      sentry,
      isEditor,
      isPreview,
      language,
      bundleName,
      flowAPI,
    });
    perf.trackEnd(marker);

    marker = perf.trackStart('initializeActions', pageReadyMarker);
    const actions = initializeActions({
      wixCodeApi,
      store,
      fedopsLogger,
    });
    const actionsPromisified = initializePromisifiedActions({
      wixCodeApi,
      compId,
      store,
    });
    perf.trackEnd(marker);

    marker = perf.trackStart('createRouter', pageReadyMarker);
    router = createRouter(store, config, wixCodeApi, compId);
    perf.trackEnd(marker);

    const pathname = getSectionPathname({ store, wixCodeApi, sectionUrl });
    const matchedRoute = await router.matchPath({ pathname });
    const route = get(matchedRoute, 'route');
    const preFetch = () =>
      Promise.all([
        router.preFetch(pathname),
        isInCategory(route)
          ? store.dispatch(fetchSiteProperties())
          : Promise.resolve(),
      ]);

    await perf.trackPromise(
      'initializeStoreBaseData',
      () =>
        initializeStoreBaseData({
          wixCodeApi,
          store,
          language,
          platformAPIs,
          config,
          context,
          bundleName,
          translationsName: 'main',
          preFetch,
          appParams,
          flowAPI,
        }),
      pageReadyMarker,
    );
    listenToInstanceChange(wixCodeApi, appParams, store);

    initI18N(getTranslations(store.getState()));
    handleLocationChange({
      wixCodeApi,
      store,
      log,
      bundleName,
      router,
      sectionUrl,
    });

    await perf.trackPromise(
      'doRouting',
      () =>
        doRouting({
          store,
          router,
          pathname: getSectionPathname({ store, wixCodeApi, sectionUrl }),
          isInitialLoad: true,
        }),
      pageReadyMarker,
    );

    log('createFeedPageController.pageReady -> done');
    const state = store.getState();

    // after initial routing is done, we subscribe to get routing change as fast as route changes, without waiting for handler to resolve
    router.onMatch((match) => store.dispatch(setRouterMatch(match)));

    const stateVersions = getInitialStateVersions(state);
    const controllerId = createControllerId();

    setProps({
      state,
      stateVersions,
      actions,
      actionsPromisified,
      isSSR,
      isRTL: isRtlLanguage(language),
      controllerId,
    });

    if (
      (isEditor || isPreview) &&
      !isFrameless({ state: store.getState(), wixCodeApi })
    ) {
      listenToEditModeChange(store);
      listenToSettingsChange(store);
    }

    refreshDataOnLogin({ wixCodeApi, store, router });
    subscribeToChange(store, stateVersions, setProps, controllerId);
    perf.trackEnd(pageReadyMarker);
  };

  return Promise.resolve({
    pageReady: () =>
      isEditor
        ? handleProvisioning(
            appParams,
            fedopsLogger,
            wixCodeApi,
            setProps,
            pageReady,
            flowAPI,
          )
        : pageReady(),
    exports: () => {},
    updateConfig: (_$w, { style: { styleParams }, publicData }) => {
      store && store.dispatch(setAppSettings({ style: styleParams }));
      handleSettingsEvent(store, publicData);
    },
  }).catch(console.error);
};
